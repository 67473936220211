import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Home() {
  return (
    <>
      <SEO
        title={"Entretien de recrutement"}
        keywords={[`entretien de recrutement`]}
      />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader
          title="Entretien de recrutement"
          header="/images/headers/18.jpg"
        />
        <Layout>
          <article>
            <div className="at-page__content">
              <h2 class="at-page__title">
                Accompagnement à l’entretien de recrutement
              </h2>
              <div class="at-page__title-divider"></div>
              <p>
                Tu as envie ou tu as enfin décroché cet entretien de recrutement
                pour le poste tant convoité, dans une entreprise qui t’attire
                particulièrement ? L’équipe a l’air vraiment passionnée, les
                valeurs alignées aux tiennes ? Alors sache que je vais tout
                faire pour te permettre d'être à l’aise face à ces
                recruteur.teuses. Rassure-toi, ils ne font pas si peur…
              </p>
              <p>
                Nous allons revoir ensemble ton curriculum vitae, ta lettre de
                motivation au besoin, relire ton portfolio si tu en as un et
                préparer tes arguments chocs pour qu’ils ne passent pas à côté
                de l’employé.e formidable que tu es. L’entrevue, qu’elle soit en
                présentiel ou en visio, ne sera plus aussi angoissante qu’elle
                peut l’être. En effet, je me permettrai également de te donner
                des conseils qui permettront à ta candidature de se démarquer et
                de plaire à tes recruteurs.teuses au travers des différentes
                étapes de recrutement.
              </p>
              <p>
                Notre séance sera d’une durée d’une heure mais sache que je
                prends en plus le temps, en amont de notre rencontre, d’analyser
                l’offre d’emploi, l’entreprise et ses valeurs, de relire tes
                documents et de proposer des modifications au besoin.
              </p>
              <p>
                Durant notre échange, j’analyserai plusieurs éléments tels que
                ton attitude, ton vocabulaire, tes tics physiques et de langage
                ou encore ta hauteur et ton ton de voix…
              </p>
              <p>
                Par la suite, nous prendrons le temps d’effectuer un suivi
                qu’importe l’issue de l’entrevue.
              </p>
              <p>
                N’oublie jamais que si ce n’est pas pour cette entreprise, tous
                les conseils prodigués ne seront pas perdus. Tu pourras les
                mettre à profit pour un autre poste dans une autre entreprise.
              </p>
            </div>
          </article>
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
